<template>
  <div>
    <!--begin::Detail-->
    <b-card header-tag="header" footer-tag="footer" class="gutter-b">
      <div class="row">
        <div class="col-md-3">
          <div class="mb-6 text-center mb-3">
            <img
              style="object-fit: scale-down"
              width="150px"
              :src="
                admin && admin.photo
                  ? admin.photo
                  : 'https://gardtrax-admin.tdpindonesia.id/dist/img/user-avatar.png'
              "
            />
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">Name :</span>
            <span>{{ admin.name }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">Email :</span>
            <span>{{ admin.email }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">Birthdate : </span>
            <span>{{ formatDate(admin.dob) }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">Phone Number :</span>
            <span>{{ admin.phone_number }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="font-weight-bold">Status :</span>
            <span>{{ admin.status }}</span>
          </div>
        </div>
      </div>
    </b-card>
    <b-button variant="primary" @click="$router.go(-1)">Back</b-button>
    <!--end::Detail-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { formatDate } from "@/utils/helper";

export default {
  name: "DetailAdmin",
  data() {
    return {
      admin: {},
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Admin", route: "/admin" },
      { title: "View" },
    ]);
  },
  created() {
    this.initData();
  },
  methods: {
    formatDate,
    initData() {
      this.$axios
        .get(
          `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/admin/detail/${this.$route.params.id}`
        )
        .then(({ data }) => {
          this.admin = data;
        });
    },
  },
};
</script>
<style lang="scss"></style>
